import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/faq": [5],
		"/faq/services": [6],
		"/how/digital-services/link-multiple-domains/dns": [~7],
		"/how/digital-services/link-multiple-domains/via": [~8],
		"/quickstart": [9],
		"/syntax": [10],
		"/tools": [11,[2]],
		"/tools/builder": [12,[2]],
		"/tools/checker": [13,[2]],
		"/tools/validator": [14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';